import { createUseStyles } from 'react-jss'

const styles = {
  cookies_wrap: {
    width: '100%',
    position: 'fixed',
    left: '0',
    bottom: '0',
    zIndex: '99999',
    padding: '30px 40px',
    backgroundColor: 'rgba(0,0,0,.8)',
    color: '#FFF',
    textAlign: 'center',

    '& a': {
      color: '#FFF',
      textDecoration: 'underline'
    },

    '& .button': {
      minWidth: '220px',
      padding: '1.5rem 1.5rem',
      backgroundColor: '#F45454',
      color: '#FFF'
    }
  },

  inner: {
    maxWidth: '700px',
    margin: '0 auto',
    textAlign: 'left'
  },

  cookies_close: {
    width: '30px',
    height: '30px',
    display: 'block',
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: '999',
    overflow: 'hidden',
    cursor: 'pointer',

    '&:before, &:after': {
      content: "''",
      width: '2px',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '50%',
      backgroundColor: '#F45454'
    },

    '&:before': {
      transform: 'rotate(45deg)'
    },

    '&:after': {
      transform: 'rotate(-45deg)'
    }
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '460px',
    margin: '0 auto',
    flexWrap: 'wrap',

    '& button': {
      marginTop: '20px'
    },

    '@media screen and (max-width: 500px)': {
      flexDirection: 'column'
    }
  }
}

export default createUseStyles(styles)
