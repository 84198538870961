import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Insurance from 'layout/Modals/insurance';
import Newsletter from 'layout/Modals/newsletter';
import { stringToUrl, destinationsIds } from '@utils/Utils';

const Footer = (props: any) => {
  const { i18n, t } = useTranslation();
  const [lang] = useState(i18n.language.split('-')[0]);
  const location = useLocation();
  const [navigation, setNavigation] = useState(false);
  const [visible, setVisible] = useState(false);

  const [modal, setModal] = useState(0);

  useEffect(() => {
    if (location.pathname.includes('/wedding/')) setNavigation(true);
    return () => setNavigation(false);
  }, [location]);

  useEffect(() => {
    setTimeout(() => setVisible(true), 500);
  }, []);

  return !visible ? null : (
    <>
      <footer>
        <div className='top' hidden={navigation}>
          <div className='flex start content in'>
            <div className='section'>
              <div className='tit'>{t('general.destinos')}</div>
              <div className='links'>
                <ul>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.dubai'))}
                      state={{ id: destinationsIds['dubai'] }}
                    >
                      abu dabhi
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.argentina'))}
                      state={{ id: destinationsIds['argentina'] }}
                    >
                      {t('destinations.argentina')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.australia'))}
                      state={{ id: destinationsIds['australia'] }}
                    >
                      {t('destinations.australia')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.canada'))}
                      state={{ id: destinationsIds['canada'] }}
                    >
                      {t('destinations.canada')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.chile'))}
                      state={{ id: destinationsIds['chile'] }}
                    >
                      {t('destinations.chile')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.cook'))}
                      state={{ id: destinationsIds['cook'] }}
                    >
                      {t('destinations.cook')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.dubai'))}
                      state={{ id: destinationsIds['dubai'] }}
                    >
                      dubai
                    </NavLink>
                  </li>
                </ul>
                <ul>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.usa'))}
                      state={{ id: destinationsIds['usa'] }}
                    >
                      {t('destinations.usa')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={t('router./interests/skiing')}>{t('destinations.skiing')}</NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.fiji'))}
                      state={{ id: destinationsIds['fiji'] }}
                    >
                      {t('destinations.fiji')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.hawaii'))}
                      state={{ id: destinationsIds['hawaii'] }}
                    >
                      {t('destinations.hawaii')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.japan'))}
                      state={{ id: destinationsIds['japan'] }}
                    >
                      {t('destinations.japan')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.maldives'))}
                      state={{ id: destinationsIds['maldives'] }}
                    >
                      {t('destinations.maldives')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.mauricio'))}
                      state={{ id: destinationsIds['mauricio'] }}
                    >
                      {t('destinations.mauricio')}
                    </NavLink>
                  </li>
                </ul>
                <ul>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.new_zealand'))}
                      state={{ id: destinationsIds['new_zealand'] }}
                    >
                      {t('destinations.new_zealand')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.peru'))}
                      state={{ id: destinationsIds['peru'] }}
                    >
                      {t('destinations.peru')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.polynesia'))}
                      state={{ id: destinationsIds['polynesia'] }}
                    >
                      {t('destinations.polynesia')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.safaris'))}
                      state={{ id: destinationsIds['safaris'] }}
                    >
                      {t('destinations.safaris')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.samoa'))}
                      state={{ id: destinationsIds['samoa'] }}
                    >
                      {t('destinations.samoa')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={t('router./destination') + '/' + stringToUrl(t('destinations.seychelles'))}
                      state={{ id: destinationsIds['seychelles'] }}
                    >
                      {t('destinations.seychelles')}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className='section'>
              <div className='tit'>{t('footer.interes')}</div>
              <div className='links'>
                <ul>
                  <li>
                    <NavLink to={t('router./interests/skiing')}>{t('interests.esqui')}</NavLink>
                  </li>
                  <li>
                    <NavLink to={t('router./interests/honeymoon')}>{t('interests.luna_de_miel')}</NavLink>
                  </li>
                  <li>
                    <NavLink to={t('router./interests/nature')}>{t('interests.naturaleza')}</NavLink>
                  </li>
                  <li>
                    <NavLink to={t('router./interests/diving')}>{t('interests.submarinismo')}</NavLink>
                  </li>
                  <li>
                    <NavLink to={t('router./interests/friends')}>{t('interests.amigos')}</NavLink>
                  </li>
                  <li>
                    <NavLink to={t('router./interests/family')}>{t('interests.familia')}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className='section'>
              <div className='tit'>{t('footer.asesoramiento')}</div>
              <div className='links'>
                <ul>
                  <li>
                    <NavLink to={t('router./staff')}>{t('footer.quienes_somos')}</NavLink>
                  </li>
                  <li>
                    <NavLink to={t('router./contact')}>{t('footer.donde_estamos')}</NavLink>
                  </li>
                  <li>
                    <NavLink to={t('router./contact')}>{t('footer.contacto')}</NavLink>
                  </li>
                  <li>
                    <span onClick={() => setModal(1)}>{t('footer.opciones_de_seguro')}</span>
                  </li>
                  <li>
                    <a
                      href={process.env.PUBLIC_URL + '/doc/contrato_de_viaje.pdf'}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {t('footer.contrato_de_viaje')}
                    </a>
                  </li>
                  <li>
                    <NavLink to={t('router./posts')}>{t('footer.blog_de_viaje')}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className='section nogrow newsletter'>
              <div className='tit'>{t('footer.mantente_informado')}</div>
              <p>{t('footer.suscribete_a_nuestro')}.</p>

              <button className='button' onClick={() => setModal(2)}>
                {t('footer.suscribirse')}
              </button>
            </div>
            <div className='address'>
              Xavi Fernández Viajes, Travessera de Gràcia, 22, 5º 1ª, 08021 Barcelona · 932 09 98 62 · info@xfviajes.com{' '}
              <br />
              <br />
              <a
                href={process.env.PUBLIC_URL + '/doc/legal_notice_' + lang + '.pdf'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('footer.aviso_legal')}
              </a>
              {' · '}
              <a
                href={process.env.PUBLIC_URL + '/doc/privacity_' + lang + '.pdf'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('footer.politica_de')}
              </a>
              {' · '}
              <a
                href={process.env.PUBLIC_URL + '/doc/cookies_' + lang + '.pdf'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('footer.politica_cookies')}
              </a>
              {' · '}
              <a
                href={process.env.PUBLIC_URL + '/doc/legal_socials_' + lang + '.pdf'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('footer.politica_sociales')}
              </a>
              {' · '}
              <a
                href={process.env.PUBLIC_URL + '/doc/condiciones_generales.pdf'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('footer.condiciones_generales')}
              </a>
              {' · '}
              <a href={process.env.PUBLIC_URL + '/doc/contrato_de_viaje.pdf'} target='_blank' rel='noopener noreferrer'>
                {t('footer.contrato_de_viaje')}
              </a>
              {' · '}
              <a
                href={process.env.PUBLIC_URL + '/doc/creditos_fotografos_web.pdf'}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('footer.creditos_fotografos_web')}
              </a>
              {' · '}
              {/* <a href={'https://www.xavifernandezviatges.com/blog'} target='_blank' rel='noopener noreferrer'>
                {t('footer.antiguo_blog')}
              </a>
              {' · '} */}
              <NavLink to={t('router./sitemap')}>{t('router.sitemap')}</NavLink>
            </div>
          </div>
        </div>

        <div className='mid'>
          <div className='content flex start'>
            <div className='logos'>
              <a href='//purelifeexperiences.com' target='_blank' rel='noopener noreferrer'>
                <img width='100' alt='' src={require('../../img/logo_pure.png')} />
              </a>
              <a href='//purelifeexperiences.com' target='_blank' rel='noopener noreferrer'>
                <img width='90' alt='' src={require('../../img/footer_logo_pure.jpg')} />
              </a>
              <a href='//www.zankyou.es/f/xavi-fernandez-viajes-271676' target='_blank' rel='noopener noreferrer'>
                <img width='100' alt='' src={require('../../img/logo_ziwa_2021.png')} />
              </a>
              <a
                href='https://www.zankyou.es/p/luna-de-miel-para-y-por-vosotros-una-experiencia-360o'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img alt='' src={require('../../img/footer_logo_zankyou.jpg')} />
              </a>
              <a href='//www.zankyou.es/f/xavi-fernandez-viajes-271676' target='_blank' rel='noopener noreferrer'>
                <img alt='' src={require('../../img/footer_logo_ziwa2016_es.jpg')} />
              </a>
              <a href='//www.zankyou.es/f/xavi-fernandez-viajes-271676' target='_blank' rel='noopener noreferrer'>
                <img alt='' src={require('../../img/footer_logo_ziwa2017_es.jpg')} />
              </a>
              <a href='//www.zankyou.es/f/xavi-fernandez-viajes-271676' target='_blank' rel='noopener noreferrer'>
                <img
                  width='110'
                  src='https://asset1.zankyou.com/img/sections/badgets/ziwa-winners/badge-ziwa2019-es.png'
                  alt=''
                />
              </a>
              <a
                href='//www.bodas.net/viaje-de-novios/xavi-fernandez-viajes--e39550'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img width='70' alt='' src={require('../../img/logo_bodas_net.png')} />
              </a>
              <a
                href='//www.bodas.net/viaje-de-novios/xavi-fernandez-viajes--e39550'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img width='70' alt='' src={require('../../img/footer_logo_bodasnet_2022.jpg')} />
              </a>
              <a href='//tahititourisme.es/es-es/' target='_blank' rel='noopener noreferrer'>
                <img alt='' src={require('../../img/footer_tahiti_tourisme.png')} />
              </a>
              <a href='//www.emotionstravelcommunity.com/' target='_blank' rel='noopener noreferrer'>
                <img alt='' src={require('../../img/footer_logo_emotions.jpg')} />
              </a>
              <a href='//www.acave.travel/es/content/xavi-fernandez-viajes' target='_blank' rel='noopener noreferrer'>
                <img alt='' src={require('../../img/footer_logo_ucave.jpg')} />
              </a>
              <a href='//www.iata.org' target='_blank' rel='noopener noreferrer'>
                <img alt='' src={require('../../img/footer_logo_iata.jpg')} />
              </a>
              <a href='//www.ceav.info/' target='_blank' rel='noopener noreferrer'>
                <img alt='' src={require('../../img/footer_logo_ceav.jpg')} />
              </a>
              <a href='//locosporviajar.org' target='_blank' rel='noopener noreferrer'>
                <img alt='' src={require('../../img/footer_logo_locos_por_viajar.jpg')} style={{ maxWidth: '150px' }} />
              </a>
              <a href='//www.biospheretourism.com/es' target='_blank' rel='noopener noreferrer'>
                <img
                  alt=''
                  src={require('../../img/footer_logo_biosphere_certified_2022.jpg')}
                  style={{ maxWidth: '80px' }}
                />
              </a>
            </div>
          </div>
        </div>

        <div className='bot'>
          <div className='content'>
            <div className='company'>
              Xavi Fernández Viatges S.L. - B63494546 - {t('footer.licencia_de_agencia')}. GCMD-178 IATA 782838-1 <br />
              <br />
              {t('footer.inscrita_en')}, {t('footer.tomo')} 36556, {t('footer.folio')} 200, {t('footer.hoja')} 293597,{' '}
              {t('footer.inscripcion')} 1a
            </div>
            <div className='socials'>
              <a href='https://www.youtube.com/channel/UCHmqEJmiO5_gdyKET_CjZqA' title='Youtube XFV' target='_new'>
                <img src={require('../../style/img/footer_logo_youtube.png')} alt='' />
              </a>
              <a href='https://www.facebook.com/xfviajes/' title='Facebook XFV' target='_new'>
                <img
                  src={require('../../style/img/footer_logo_facebook.png')}
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                  alt=''
                />
              </a>
              <a href='https://instagram.com/xfviajes' title='Instagram XFV' target='_new'>
                <img src={require('../../style/img/footer_logo_instagram.png')} alt='' />
              </a>
            </div>
          </div>
        </div>
      </footer>

      <Insurance visible={modal === 1} onClose={() => setModal(0)} />
      <Newsletter visible={modal === 2} onClose={() => setModal(0)} />
    </>
  );
};

export default Footer;
