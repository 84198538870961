import React, { useState } from 'react';
import Modal from '@components/Modal';
import { useTranslation } from 'react-i18next';

import Checkbox from '@components/Forms/Checkbox';
import useValidation from '@components/Forms/Validation/useValidation';
import { LangDefault } from 'helpers/config';

const Insurance = (props: any) => {
  const { i18n, t } = useTranslation();
  const [lang] = useState(i18n.language.split('-')[0] || LangDefault);
  const { validate, errors }: any = useValidation();

  return (
    <>
      <Modal visible={props.visible} onClose={props.onClose}>
        <div className='modal newsletter'>
          <div className='inner'>
            <h2>{t('modals.newsletter.title')}</h2>
            <span dangerouslySetInnerHTML={{ __html: t('modals.newsletter.txt') }} />
            &nbsp;
            <a
              href={process.env.PUBLIC_URL + '/doc/privacity_' + lang + '.pdf'}
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('general.politica_de')}
            </a>
            .
            <br />
            <br />
            <div className='check'>
              <Checkbox name='terms' validation={validate} />
              <span>{t('modals.newsletter.he_leido')}</span>
            </div>
            <br />
            <br />
            <a
              href='https://us16.list-manage.com/subscribe?u=0d9357ee1ded6701469b2e345&id=d551925931'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button className='button' disabled={errors?.terms?.state} onClick={props.onClose}>
                suscribirse
              </button>
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Insurance;
