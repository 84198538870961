import React from 'react'
import './style.css'

type Props = {
  name?: string
  checked?: boolean
  disabled?: boolean
  validation?: any
  onToggle?: () => any
}

const Checkbox = (props: Props) => {
  const { validation, name, checked, onToggle, disabled } = props
  return (
    <>
      <input
        type='checkbox'
        defaultChecked={checked}
        id={name}
        name={name}
        className='check-box'
        ref={validation && (e => validation(e, 'accepted'))}
        onClick={onToggle && onToggle}
        disabled={disabled}
      />
      <label htmlFor={name} className='check-box' />
    </>
  )
}

export default Checkbox
