import React from 'react';
import Modal from '@components/Modal';
import { useTranslation } from 'react-i18next';

const Insurance = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal visible={props.visible} onClose={props.onClose}>
        <div className='modal insurance'>
          <div className='inner'>
            <h2>{t('modals.insurance.opciones_de')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('modals.insurance.txt') }} />
            <br />
            <div className='links'>
              <a href={process.env.PUBLIC_URL + '/doc/seguro_arag.pdf'} target='_blank' rel='noopener noreferrer'>
                {t('modals.insurance.seguro_1')}
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Insurance;
