import React, { createElement, Suspense, lazy } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = lazy(() => import('layout/Home/home'));
const C404 = lazy(() => import('layout/404/404'));
const Destination = lazy(() => import('layout/Destinations/destination'));
const Destinations = lazy(() => import('layout/Destinations/destinations'));
const DestinationVisit = lazy(() => import('layout/Destinations/destinationVisit'));
const Weddings = lazy(() => import('layout/Weddings/weddings'));
const Wedding = lazy(() => import('layout/Weddings/wedding'));
const Interests = lazy(() => import('layout/Interests/interests'));
const Family = lazy(() => import('layout/Interests/family'));
const Diving = lazy(() => import('layout/Interests/diving'));
const Nature = lazy(() => import('layout/Interests/nature'));
const HoneyMoon = lazy(() => import('layout/Interests/honeymoon'));
const Friends = lazy(() => import('layout/Interests/friends'));
const Skiing = lazy(() => import('layout/Interests/skiing'));
const Blog = lazy(() => import('layout/Blog/blog'));
const Post = lazy(() => import('layout/Blog/post'));
const Staff = lazy(() => import('layout/Staff/staff'));
const RSC = lazy(() => import('layout/Rsc/rsc'));
const Contact = lazy(() => import('layout/Contact/contact'));
const Loading = lazy(() => import('layout/Loading/loading'));
const WeddingContributions = lazy(() => import('layout/Weddings/contributions'));
const Sitemap = lazy(() => import('layout/Sitemap/sitemap'));

const routes = [
  { path: '/', element: Home, title: 'home', notInDrawer: true, inDrawerMobile: true },
  { path: '/destinations', element: Destinations, title: 'destinations' },
  { path: '/destination', multiple: true, element: Destination, title: 'destination', notInDrawer: true },
  {
    path: '/destination/visit',
    multiple: true,
    element: DestinationVisit,
    title: 'destinationVisit',
    notInDrawer: true
  },
  { path: '/interests', element: Interests, title: 'interests' },
  { path: '/interests/family', element: Family, title: 'family', notInDrawer: true },
  { path: '/interests/diving', element: Diving, title: 'diving', notInDrawer: true },
  { path: '/interests/nature', element: Nature, title: 'nature', notInDrawer: true },
  { path: '/interests/honeymoon', element: HoneyMoon, title: 'honeymoon', notInDrawer: true },
  { path: '/interests/friends', element: Friends, title: 'friends', notInDrawer: true },
  { path: '/interests/skiing', element: Skiing, title: 'skiing', notInDrawer: true },
  { path: '/weddings', element: Weddings, title: 'weddings' },
  { path: '/weddings/contributions/:id', element: WeddingContributions, title: 'contributions', notInDrawer: true },
  { path: '/weddings/:id', element: Wedding, title: 'wedding', notInDrawer: true },
  { path: '/staff', element: Staff, title: 'staff' },
  { path: '/rsc', element: RSC, title: 'rsc' },
  { path: '/posts', element: Blog, title: 'blog' },
  { path: '/posts/post/:id', element: Post, title: 'view_post', notInDrawer: true },
  { path: '/contact', element: Contact, title: 'contact' },
  { path: '/contacto', element: Contact, title: 'contact', notInDrawer: true },
  { path: '/loading', element: Loading, title: 'loading', notInDrawer: true },
  { path: '/sitemap', element: Sitemap, title: 'sitemap', notInDrawer: true }
];

export const Router = () => {
  const { t } = useTranslation();

  return (
    <Routes>
      {routes.map((route, index) => {
        let path = t('router.' + route.path);
        path += route.multiple ? '/*' : '';

        return (
          <Route
            key={index}
            path={path}
            element={<Suspense fallback={null}>{createElement(route.element)}</Suspense>}
          />
        );
      })}
      {/* @ts-ignore */}
      <Route path='*' element={C404} />
    </Routes>
  );
};
/********/

/* Drawer */
export const LinksDrawer = () => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language.split('-')[0];

  return (
    <>
      {routes.map((route, index) => {
        if (lang === 'ca' && route.path === '/posts')
          return (
            <li key={index}>
              <a href='https://www.xavifernandezviajes.com/blog-de-viajes' rel='noopener noreferrer'>
                BLOG
              </a>
            </li>
          );

        return (
          !route.notInDrawer && (
            <li key={index}>
              <NavLink to={t('router.' + route.path) || ''} className={({ isActive }) => (isActive ? ' selected' : '')}>
                {t('router.' + route.title)}
              </NavLink>
            </li>
          )
        );
      })}
    </>
  );
};
/**********/

/* Drawer */
export const LinksDrawerMobile = () => {
  const { t } = useTranslation();

  return (
    <>
      {routes.map((route, index) => {
        return (
          (!route.notInDrawer || route.inDrawerMobile) && (
            <li key={index}>
              <NavLink to={t('router.' + route.path)} className={({ isActive }) => (isActive ? ' selected' : '')}>
                {t('router.' + route.title)}
              </NavLink>
            </li>
          )
        );
      })}
    </>
  );
};
/**********/
