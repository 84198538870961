export function stringToUrl(str) {
  return String(str)
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace('_', '-')
    .replace(/[^a-zA-Z0-9 -]+/g, '')
    .split(' ')
    .join('-')
    .replaceAll('--', '-')
}
