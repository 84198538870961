export function gtag_report_conversion(url) {
  var callback = function() {
    if (typeof url != 'undefined') {
      setTimeout(() => {
        window.location = url;
      }, 3000);
    }
  };
  gtag('event', 'conversion', {
    send_to: 'AW-1019387565/xA05CMvHtQUQrb2K5gM',
    event_callback: callback
  });
  return false;
}
