import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Config, LangDefault } from './config';
// @ts-ignore
import { env } from '../../public/env.js';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: env.lang,
    fallbackLng: LangDefault,
    debug: false,
    nsSeparator: '->',
    backend: {
      loadPath: Config.baseURL + '/locales/{{lng}}/{{ns}}.json'
    }
  });

export default i18n;
