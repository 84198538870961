const styles = (theme: any) => ({
  noscroll: {
    overflow: 'hidden'
  },

  hidden: {
    display: 'none !important'
  },

  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '1000',
  },

  bg: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '997',
    backgroundColor: 'rgba(255,255,255,.85)',
  },

  close: {
    width: '30px',
    height: '30px',
    display: 'block',
    position: 'absolute',
    top: '15px',
    right: '15px',
    zIndex: '999',
    overflow: 'hidden',
    cursor: 'pointer',

    '&:before, &:after': {
      content: '\'\'',
      width: '2px',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '50%',
      backgroundColor: theme.colorSecondary || '#F45454',
    },

    '&:before': {
      transform: 'rotate(45deg)'
    },

    '&:after': {
      transform: 'rotate(-45deg)'
    },
  },

  inner: {
    width: '90%',
    maxWidth: '1080px',
    position: 'absolute',
    alignSelf: 'center',
    zIndex: '998',
  },

  fullSize: {
    width: '100%',
    height: '100vh',
    overflow: 'scroll',
    maxWidth: '100%',
    marginTop: '0vh',
  }

});

export default styles;