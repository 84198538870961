import { destinationsIds } from './config';
import { stringToUrl } from './stringToUrl';

export const replaceImgFromCkEditor = (txt: string): string => {
  let frag = document.createRange().createContextualFragment(txt);
  // frag.querySelectorAll('.image').forEach(img => {
  //   let src = img.querySelector('img').getAttribute('src')
  //   let div = document.createElement('div')
  //   img.classList.forEach(c => div.classList.add(c))

  //   let bg = document.createElement('div')
  //   bg.classList.add('bg')
  //   bg.style.backgroundImage = 'url(' + src + ')'
  //   div.appendChild(bg)
  //   frag.replaceChild(div, img)
  // })

  // frag.querySelectorAll('.image').forEach(img => {
  //   let reset = document.createElement('div')
  //   reset.classList.add('reset')
  //   frag.insertBefore(reset, img)
  // })

  let reset = document.createElement('div');
  reset.classList.add('reset');
  frag.appendChild(reset);

  const serializer = new XMLSerializer();
  let res = serializer.serializeToString(frag);

  return res;
};

export const formatPostPreviewContent = (txt: string): string => {
  return '<p>' + txt.replace(/<p>/g, '').replace(/<\/p>/g, '<br>') + '</p>';
};

export const getLabelFromId = (id: string): string => {
  const dest = Object.entries(destinationsIds).find(([k, v]) => {
    if (v === id) return k;
    return '';
  });
  return dest?.[0] ?? '';
};

export const getIdFromLabel = (label: string): string => {
  const id = Object.entries(destinationsIds).find(([k, v]) => {
    if (k === label) return v;
    return '';
  });
  return id?.[1] || '';
};

export const getIdFromLocationPath = (path: string, lang: string): string => {
  const label = Object.entries(lang).find(([k, v]) => {
    if (stringToUrl(v) === path) return k;
    return '';
  });
  return getIdFromLabel(label?.[0] || '');
};
