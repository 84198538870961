import { Blog, IBlogAppAction, BlogActionType } from './blogHelpers'

export const BlogReducers = (
  state: Blog = new Blog(),
  action: IBlogAppAction
): Blog => {
  switch (action.type) {
    case BlogActionType.SET_VARS:
      return {
        ...state,
        first: action.payload.first,
        type: action.payload.type,
        labels: action.payload.labels,
        page: action.payload.page,
        posts: action.payload.posts
      }
    default:
      return state
  }
}
