import React, { useState } from 'react'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import { deleteCookie, setCookie, getCookie } from '@utils/Utils'
import { LangDefault } from 'helpers/config'

const Cookies = () => {
  const classes = styles()
  const { i18n, t } = useTranslation()
  const [lang, setLang] = useState(LangDefault)
  const [cookies, setCookies] = useState(getCookie('xfv_consent_cookie') === 'true')
  const [visibleConfigCookies, setVisibleConfigCookies] = useState(false)
  // const [cookiesPersonalizacion, setCookiesPersonalizacion] = useState(
  //   getCookie('xfv_personalization_cookie') === 'true'
  // )
  const [cookiesAnaliticas, setCookiesAnaliticas] = useState(getCookie('xfv_analytics_cookie') !== 'false')
  // const [cookiesPublicidad, setCookiesPublicidad] = useState(getCookie('xfv_advertising_cookie') === 'true')

  const handleSetCookie = () => {
    setCookie('xfv_consent_cookie', true, 365)
    setCookies(true)
  }

  const handleToggleConfigCookies = () => {
    setVisibleConfigCookies(true)
    setCookies(true)
  }

  // const handleToggleCookiesPersonalizacion = () => {
  //   setCookiesPersonalizacion(!cookiesPersonalizacion)
  // }

  const handleToggleCookiesAnaliticas = () => {
    setCookiesAnaliticas(!cookiesAnaliticas)
  }

  // const handleToggleCookiesPublicidad = () => {
  //   setCookiesPublicidad(!cookiesPublicidad)
  // }

  const handleSaveCookiesConfig = () => {
    // setCookie('xfv_personalization_cookie', cookiesPersonalizacion, 365)
    setCookie('xfv_analytics_cookie', cookiesAnaliticas, 365)

    if (!cookiesAnaliticas) {
      deleteCookie('_gid')
      deleteCookie('_ga')
      deleteCookie('_gat_gtag_UA_153287340_1')
    }
    //setCookie('xfv_advertising_cookie', cookiesPublicidad, 365)
    setVisibleConfigCookies(false)
    handleSetCookie()
  }

  React.useEffect(() => {
    setLang(i18n.language.split('-')[0])
  }, [i18n.language])

  return (
    <>
      <div className={classes.cookies_wrap} hidden={cookies}>
        <div>
          {t('modals.cookies.txt1')}
          <br />
          <br />
          {t('modals.cookies.txt2')}
          <a href={process.env.PUBLIC_URL + '/doc/cookies_' + lang + '.pdf'} target='_blank' rel='noopener noreferrer'>
            {t('footer.politica_cookies')}
          </a>
          .
        </div>
        <br />

        <div className={classes.buttons}>
          <button className='button' onClick={handleSetCookie}>
            {t('general.aceptar')} {t('general.cookies')}
          </button>
          <button className='button' onClick={handleToggleConfigCookies}>
            {t('general.configurar')} {t('general.cookies')}
          </button>
        </div>
      </div>

      <div className={classes.cookies_wrap} hidden={!visibleConfigCookies}>
        <div className={classes.inner}>
          <h2>{t('modals.cookies.config_1')}</h2>
          <br />
          <p>{t('modals.cookies.config_2')}</p>
          <br />
          <div>
            <h3>• {t('modals.cookies.config_3')}</h3> {t('modals.cookies.config_4')}
            {/* <br />
            <br />
            <h3>• Cookies de Personalización</h3> Estas cookies están relacionadas con características generales como,
            por ejemplo, el navegador que utilizas, y podrás disponer de una experiencia y contenidos personalizados.
            <br />
            <br />
            <p className='flex nowrap' style={{ alignItems: 'flex-start' }}>
              <input
                type='checkbox'
                onChange={handleToggleCookiesPersonalizacion}
                defaultChecked={cookiesPersonalizacion}
                style={{ marginTop: '3px', marginRight: '5px' }}
              />
              <span>
                No quiero cookies de Personalización (Con tu selección no podemos ofrecerte una navegación y contenidos
                personalizados)
              </span>
            </p> */}
            <br />
            <br />
            <h3>• {t('modals.cookies.config_5')}</h3> {t('modals.cookies.config_6')}
            <br />
            <br />
            <a
              href={process.env.PUBLIC_URL + '/doc/cookies_' + lang + '.pdf'}
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('modals.cookies.list_analytics_cookies')}
            </a>
            <br />
            <br />
            <p className='flex nowrap' style={{ alignItems: 'flex-start' }}>
              <input
                type='checkbox'
                onChange={handleToggleCookiesAnaliticas}
                defaultChecked={!cookiesAnaliticas}
                style={{ marginTop: '3px', marginRight: '5px' }}
              />
              <span>{t('modals.cookies.config_7')}</span>
            </p>
            {/* <br />
            <br />
            <h3>• Cookies de Publicidad</h3> Permiten que la publicidad que te mostramos sea personalizada y relevante
            para ti. Gracias a estas cookies no verás anuncios que no te interesen. Podrá disponer de una publicidad
            adaptada a ti y a tus preferencias.
            <br />
            <br />
            <p className='flex nowrap' style={{ alignItems: 'flex-start' }}>
              <input
                type='checkbox'
                onChange={handleToggleCookiesPublicidad}
                checked={cookiesPublicidad}
                style={{ marginTop: '3px', marginRight: '5px' }}
              />
              <span>
                No quiero cookies de Publicidad (Con tu selección no podemos ofrecerte una navegación y contenidos
                personalizados)
              </span>
            </p> */}
          </div>
          <br />
          <br />
          <p className='tcenter'>
            <button className='button' onClick={handleSaveCookiesConfig}>
              {t('general.guardar')} {t('general.configuracion')}
            </button>
          </p>
        </div>
      </div>
    </>
  )
}

export default Cookies
