import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export const useTags = (id: string = 'default') => {
  const { t } = useTranslation()

  const oldTags = useRef({
    title: document.title,
    description: document.querySelector('meta[name="description"]').getAttribute('content'),
    keywords: document.querySelector('meta[name="keywords"]').getAttribute('content')
  })

  const setTags = (title, description, keywords) => {
    document.title = title
    document.querySelector('meta[name="description"]').setAttribute('content', description)
    document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
  }

  useEffect(() => {
    if (id) setTags(t(`tags.${id}.title`), t(`tags.${id}.desc`), t(`tags.${id}.meta1`) + ' ' + t(`tags.${id}.meta2`))
    return () => setTags(oldTags.current.title, oldTags.current.description, oldTags.current.keywords)
  }, [])

  return { setTags }
}
