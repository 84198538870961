const isExisty = function (val) {
  return val !== null && val !== undefined;
};

const isEmpty = function (val) {
  if (val instanceof Array) {
      return val.length === 0;
  }
  return val === '' || !isExisty(val);
};

const isEmptyTrimed = function (val) {
  if (typeof val === 'string') {
      return val.trim() === '';
  }
  return true;
};

export const validator = {
  matchRegexp: (val, regexp) => {
      const validationRegexp = (regexp instanceof RegExp ? regexp : (new RegExp(regexp)));
      return validationRegexp.test(val);
  },

  isEmail: val => validator.matchRegexp(val,/^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),

  isEmpty: val => isEmpty(val),

  required: val => !isEmpty(val),

  accepted: val => val === true,

  trim: val => !isEmptyTrimed(val),

  isNumber: val => validator.matchRegexp(val, /^\d*$/),

  isPositive: val => validator.matchRegexp(val, /^[1-9]\d*$/),

  isInteger: val => validator.matchRegexp(val, /^-?\d*$/),

  isFloat: val => validator.matchRegexp(val, /^(?:-?[1-9]\d*|-?0)?(?:\.\d+)?$/i),

  maxNumber: (val, max) => isEmpty(val) || parseInt(val, 10) <= parseInt(max, 10),

  minNumber: (val, min) => isEmpty(val) || parseInt(val, 10) >= parseInt(min, 10),

  maxFloat: (val, max) => isEmpty(val) || parseFloat(val) <= parseFloat(max),

  minFloat: (val, min) => isEmpty(val) || parseFloat(val) >= parseFloat(min),

  isString: val => !isEmpty(val) || typeof val === 'string' || val instanceof String,
  minLength: (val, length) => val.length >= length,
  maxLength: (val, length) => val.length <= length,

  // eslint-disable-next-line no-undef
  isFile: val => val instanceof File,
  maxFileSize: (val, max) => validator.isFile(val) && val.size <= parseInt(max, 10),
  allowedExtensions: (val, fileTypes) => validator.isFile(val) && fileTypes.split(',').indexOf(val.type) !== -1,
};

export const validate = (val,rule) => {
  const r = rule.split(':');
  switch (r[0]) {
    case 'required':
      return validator.required(val);
    case 'email':
      return validator.isEmail(val);
    case 'accepted':
      return validator.accepted(val);
    case 'number':
      return validator.isNumber(val);
    case 'positive':
      return validator.isPositive(val);
    case 'integer':
      return validator.isInteger(val);
    case 'float':
      return validator.isFloat(val);
    case 'minLength':
      return validator.minLength(String(val),r[1]);
    case 'maxLength':
      return validator.maxLength(String(val),r[1]);
    default:
      return false;
  }
}