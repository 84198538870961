import { UI, IUIAppAction, UIActionType } from './uiHelpers'

export const UIReducers = (state: UI = new UI(), action: IUIAppAction): UI => {
  switch (action.type) {
    case UIActionType.LOCATION_SET:
      return { ...state, location: action.payload }
    case UIActionType.SPINNER_ON:
      return { ...state, spinner: true }
    case UIActionType.SPINNER_OFF:
      return { ...state, spinner: false }
    case UIActionType.DRAWER_ON:
      return { ...state, drawer: true }
    case UIActionType.DRAWER_OFF:
      return { ...state, drawer: false }
    case UIActionType.SET_SCROLL:
      return { ...state, scroll: action.payload }
    default:
      return state
  }
}
