import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'react-jss';

/* Apollo */
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, concat } from '@apollo/client';
import { Globals, cleanTypenameLink } from '@utils/Utils';

/* Custom */
import { Config } from './helpers/config';
import { store } from './helpers/store';
import theme from './helpers/theme';
import Layout from './layout';
import 'typeface-open-sans';

/* Apollo */
const httpLink = new HttpLink({ uri: Globals.graphqlUri });
const cache = new InMemoryCache();
const client = new ApolloClient({
  link: concat(cleanTypenameLink, httpLink),
  cache
});

const App = () => {
  return (
    <>
      <Suspense fallback=''>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ApolloProvider client={client}>
              <BrowserRouter basename={Config.baseURL}>
                <Layout />
              </BrowserRouter>
            </ApolloProvider>
          </ThemeProvider>
        </Provider>
      </Suspense>
    </>
  );
};

export default App;
