import React from 'react';
import { createRoot } from 'react-dom/client';
import './style/base.scss';
import './style/index.scss';
import './style/responsive.scss';
import App from './App';
import 'helpers/i18n';
import * as serviceWorker from './serviceWorker';

const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

serviceWorker.unregister();
