import { Action } from 'redux';
import { UIConfig } from 'helpers/config';

export interface IUI {
  location: string;
  spinner: boolean;
  drawer: boolean;
  scroll: number;
}

export class UI implements IUI {
  location: string;
  spinner: boolean;
  drawer: boolean;
  scroll: number;

  constructor() {
    this.location = '';
    this.spinner = false;
    this.drawer = UIConfig.drawer;
    this.scroll = 0;
  }
}

export enum UIActionType {
  LOCATION_SET = 'LOCATION_SET',
  SPINNER_ON = 'SPINNER_ON',
  SPINNER_OFF = 'SPINNER_OFF',
  DRAWER_ON = 'DRAWER_ON',
  DRAWER_OFF = 'DRAWER_OFF',
  SET_SCROLL = 'SET_SCROLL'
}

export interface IUIAppAction extends Action<UIActionType> {
  payload?: any;
}
