import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LinksDrawer } from 'helpers/routes';
import { useTranslation } from 'react-i18next';
import {
  Config //, Langs
} from 'helpers/config';
import NavMobile from 'components/NavMobile';

const Header = ({ noNavigation = false, ...props }: any) => {
  const { i18n } = useTranslation();
  const [navigation, setNavigation] = useState(false);
  const [lang] = useState(i18n.language.split('-')[0]);
  const [langSel, setLangSel] = useState(false);
  // const languageSelector = Langs.map((l: any) => {
  //   return (
  //     <div key={l} className={`lang ${l === lang ? 'selected' : ''}`} onClick={() => changeLanguage(l)}>
  //       {l}
  //     </div>
  //   )
  // })

  const toggleLangSel = () => {
    setLangSel(!langSel);
  };

  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng)
  //   setLang(lng)
  //   props.navigate('/')
  // }

  React.useEffect(() => {
    if (noNavigation) setNavigation(true);
    //if (location.pathname.includes('/wedding/')) setNavigation(true)
    //return () => setNavigation(false)
  }, [noNavigation]);

  return (
    <>
      <header>
        <div className='leftbar' />
        <div className='bg' hidden={navigation} />

        <div className='content'>
          <NavLink to={'/'} className='logo-link'>
            <div className='logo'></div>
          </NavLink>
          <nav className='nav-desktop' hidden={navigation}>
            <ul>
              <LinksDrawer />
            </ul>
          </nav>
          <div className='right'>
            <div
              className='tlf' //hidden={langSel}
            >
              <a href='tel:+34-932099862'>932 099 862</a>
            </div>
            <div className={`langs ${langSel ? 'open' : ''}`}>
              <div className='bg' hidden={!langSel} onClick={toggleLangSel} />
              <div className='lang-select' onClick={toggleLangSel}>
                {/* {!langSel && <div className='lang'>{lang}</div>} */}
                {/* {langSel && languageSelector} */}
                {/* {languageSelector} */}
                <div key={'es'} className={`lang ${'es' === lang ? 'selected' : ''}`}>
                  <a href={Config.urlES}>es</a>
                </div>
                <div key={'ca'} className={`lang ${'ca' === lang ? 'selected' : ''}`}>
                  <a href={Config.urlCA}>ca</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NavMobile />
      </header>
    </>
  );
};

export default Header;
