import { useState, useRef } from 'react'
import { validate as val } from './validator'
import './style.css'

const useValidation = () => {
  const fields: any = useRef({ size: 0 })
  const [errors, setErrors]: any = useState()
  const [values, setValues]: any = useState()

  const error = (name: string) => {
    const f = fields.current[name]
    if (!f.error) {
      f.error = true
      setErrors((errors: any) => ({
        ...errors,
        [name]: { state: true, msg: f.msg },
        size: errors.size + 1
      }))
    }

    if (f.touch) {
      f.ref.setAttribute('data-error', true)
      if (f.msg) {
        if (f.ref.nextSibling.classList.contains('error-msg')) {
          f.ref.nextSibling.setAttribute('data-error', true)
        }
      }
    }
  }

  const ok = (name: string) => {
    const f = fields.current[name]
    if (f.error) {
      f.error = false
      f.ref.setAttribute('data-error', false)
      setErrors((errors: any) => ({
        ...errors,
        [name]: { state: false, msg: f.msg },
        size: errors.size - 1
      }))
      if (f.msg && f.ref.nextSibling.classList.contains('error-msg'))
        f.ref.nextSibling.setAttribute('data-error', false)
    }
  }

  const checkValid = (e: any) => {
    const t = e.target ? e.target : e // Event / Element
    const v =
      t.type === 'checkbox' ? t.checked : fields.current[t.name].ref.value || ''
    const rules = fields.current[t.name].rules
    let err = false

    rules.split('|').forEach((r: any) => {
      if (!err) {
        err = !val(v, r)
        if (err) {
          error(t.name)
          return
        }
      }
    })

    if (!err) ok(t.name)
  }

  const touch = (e: any) => {
    fields.current[e.target.name].touch = true
  }

  const validate = (el: any, rules: string, msg: string = '') => {
    if (!el || fields.current[el.name]) return
    if (msg)
      el.insertAdjacentHTML(
        'afterend',
        `<div class="error-msg">${msg
          .split('|')
          .map(e => `* ${e}`)
          .join('<br />')}</div>`
      )

    const name = el.name
    const c = fields.current
    c[name] = { ref: el, touch: false, error: true, rules: rules, msg: msg }

    c[name].ref.addEventListener('blur', touch)
    c[name].ref.addEventListener('blur', checkValid)
    if (el.type === 'checkbox' || el.type === 'select-one') {
      c[name].ref.addEventListener('change', handleChange)
      c[name].ref.addEventListener('change', checkValid)
    } else {
      c[name].ref.addEventListener('input', handleChange)
      c[name].ref.addEventListener('input', checkValid)
    }

    c.size++
    setErrors((errors: any) => ({
      ...errors,
      [name]: { state: true, msg: msg },
      size: fields.current.size
    }))
    return c[name].ref
  }

  const handleChange = (e: any) => {
    const t = e.target
    const v = t.type === 'checkbox' ? t.checked : t.value
    setValues((state: any) => {
      return { ...state, [t.name]: v }
    })
    fields.current[t.name].value = v
  }

  const setValue = (name: string, value: any) => {
    if (fields.current[name]) {
      const c = fields.current[name]
      const v = value
      if (c.ref.type === 'checkbox') c.ref.checked = v
      c.touch = true
      c.value = v
      c.ref.value = v
      setValues((state: any) => {
        return { ...state, [name]: value }
      })
      checkValid(c.ref)
    }
  }

  const resetValue = (name: string, value: any) => {
    if (fields.current[name]) {
      const c = fields.current[name]
      const v = value
      if (c.ref.type === 'checkbox') c.ref.checked = v
      c.touch = false
      c.value = v
      c.ref.value = v
      setValues((state: any) => {
        return { ...state, [name]: value }
      })
      checkValid(c.ref)
    }
  }

  return {
    validate,
    values,
    errors,
    setValue,
    resetValue
  }
}

export default useValidation
