/* Global */
let setup
let baseUri

if (process.env.NODE_ENV === 'production') {
  baseUri = '//www.xavifernandezviajes.com'
  setup = {
    editorWidth: 1200,
    baseUri,
    graphqlUri: baseUri + '/cms-data-provider-mysql/graphql.php',
    mediaUri: baseUri + '/cms-media-php/media/',
    imageUploadUri: baseUri + '/cms-media-php/upload.php',
    getImageUri: baseUri + '/cms-media-php/get.php?img=',
    mailUri: baseUri + '/cms-mail-php/index.php',
    tpvUri: baseUri + '/cms-tpv-php/index.php',
    csvUri: baseUri + '/cms-tpv-php/contributions-export-csv.php'
  }
} else {
  baseUri = '//localhost'
  setup = {
    editorWidth: 1200,
    baseUri,
    graphqlUri: baseUri + '/cms-data-provider-mysql/graphql.php',
    mediaUri: baseUri + '/cms-media-php/media/',
    imageUploadUri: baseUri + '/cms-media-php/upload.php',
    getImageUri: baseUri + '/cms-media-php/get.php?img=',
    mailUri: baseUri + '/cms-mail-php/index.php',
    tpvUri: baseUri + '/cms-tpv-php/index.php',
    csvUri: baseUri + '/cms-tpv-php/contributions-export-csv.php'
  }
}
export const Globals = setup

/* Plugins */

export const insaneConfigMin = {
  allowedTags: ['p', 'strong', 'br']
}

export const insaneConfigFull = {
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    img: ['src', 'srcset', 'width', 'height', 'sizes'],
    figure: ['style']
  },
  allowedClasses: {
    figure: ['image', 'image-style-align-left', 'image-style-align-right']
  },
  allowedTags: [
    'a',
    'article',
    'b',
    'blockquote',
    'br',
    'caption',
    'code',
    'del',
    'details',
    'div',
    'em',
    'figure',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'i',
    'img',
    'ins',
    'kbd',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'section',
    'span',
    'strike',
    'strong',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'th',
    'thead',
    'tr',
    'u',
    'ul'
  ]
}

/* Custom */
export const Destinations = [
  'polynesia',
  'australia',
  'new_zealand',
  'japan',
  'cook',
  'fiji',
  'samoa',
  'maldives',
  'safaris',
  'seychelles',
  'mauricio',
  'peru',
  'argentina',
  'chile',
  'hawaii',
  'usa',
  'canada',
  'dubai',
  'skiing'
]

export const destinationsIds = {
  argentina: '1e9b159c-23ca-61f0-4b52-ac446ec89836',
  australia: '1e9b159f-753c-69e0-3474-0e78067dfd7e',
  canada: '1e9b159f-e081-68e0-c92d-f6724d44d4e3',
  chile: '1e9b15a0-27c1-64d0-df70-cb02825f4568',
  cook: '1e9b15a0-8ebb-69b0-022f-096736f9f6fd',
  dubai: '1e9b15a0-e667-6740-f595-2b7773c4a71a',
  skiing: '1e9b15a1-a3d7-68c0-f9c0-f2fc76488c6e',
  fiji: '1e9b15a1-eaa2-61b0-f422-dedd21509fac',
  hawaii: '1e9b15a2-89a1-63b0-1fce-e0a6b55eb361',
  japan: '1e9b15a2-eee4-6150-f60f-eed47b60bdb3',
  maldives: '1e9b15a3-d865-6ea0-6f75-733dab2113ff',
  mauricio: '1e9b15a4-3f3e-60a0-0149-d23d244fb04a',
  new_zealand: '1e9b15a4-a19a-6b40-5782-c4031911a30a',
  peru: '1e9b15a4-edbe-6e40-1a85-88c97c2690f5',
  polynesia: '1e9b15a5-45db-60b0-c41f-8a6066d1ae3c',
  safaris: '1e9b15a6-84ed-62c0-e553-5923ef30c7b8',
  samoa: '1e9b15a6-d7b4-6940-deba-2e447406a925',
  seychelles: '1e9b15a7-7186-6050-bb58-e8aa431c073c',
  usa: '1e9a4b76-c32f-64c0-2f4c-93206369d557',
  departures: '1e9b467b-2452-6d60-5106-36a4f931e6c2',
  dolomites: '1e9b469b-1676-65a0-b832-2785fe964013',
  aosta: '1e9b467b-9f9d-6560-27c4-815c2475ba54',
  alps: '1e9b467c-77e8-66e0-1788-61f772dc66a0',
  eusa: '1e9b467d-b64a-6c70-3574-b9ef67a49d4c',
  ecanada: '1e9b467e-8a13-6160-bb6b-0a4514c0b2b9',
  eargentina: '1e9b467f-05d0-6550-9613-3ae7550fe938',
  echile: '1e9b467f-7c89-6660-c0b6-6169d3d7f4b3',
  enew_zealand: '1e9b4680-0936-6950-5a1e-abe3e9b6ce19',
  ejapan: '1e9b4680-cdf9-6ad0-aded-59c073db37b0'
}

export const Filters = {
  general: ['family', 'diving', 'nature', 'honeymoon', 'friends', 'skiing'],
  destinations: [
    'argentina',
    'australia',
    'canada',
    'chile',
    'cook',
    'dubai',
    'fiji',
    'hawaii',
    'japan',
    'maldives',
    'mauricio',
    'new_zealand',
    'peru',
    'polynesia',
    'safaris',
    'samoa',
    'seychelles',
    'usa',
    'departures',
    'dolomites',
    'aosta',
    'alps',
    'eusa',
    'ecanada',
    'eargentina',
    'echile',
    'enew_zealand',
    'ejapan',
    'others'
  ],
  others: []
}

export const FiltersWeb = {
  general: ['family', 'diving', 'nature', 'honeymoon', 'friends', 'skiing'],
  destinations: [
    'argentina',
    'australia',
    'canada',
    'chile',
    'cook',
    'dubai',
    'fiji',
    'hawaii',
    'japan',
    'maldives',
    'mauricio',
    'new_zealand',
    'peru',
    'polynesia',
    'safaris',
    'samoa',
    'seychelles',
    'usa'
  ],
  others: []
}
