import React from 'react'
import withStyles from 'react-jss'
import styles from './styles'

type ModalProps = {
  styleBg?: object
  classNameInner?: string
  className?: string
  classes: any
  children?: React.ReactNode
  visible?: boolean
  fullSize?: any
  noClose?: any
  noCloseBg?: any
  onClose?: any
}

const Modal = (props: ModalProps) => {
  const { styleBg, classNameInner, className, classes, noClose, noCloseBg, visible, children, onClose } = props
  const [show, setShow] = React.useState(visible)

  const handleCloseModal = () => {
    if (!noClose) {
      document.body.classList.toggle(classes.noscroll, false)
      setShow(false)
      if (onClose) onClose()
    }
  }

  React.useEffect(() => {
    setShow(visible)
    visible && document.body.classList.toggle(classes.noscroll, true)
    return () => {
      document.body.classList.toggle(classes.noscroll, false)
    }
  }, [visible, classes.noscroll])

  return (
    <>
      <div className={`${classes.root} ${className} ${!show && 'hidden'}`}>
        <div className={classes.bg} onClick={!noCloseBg ? handleCloseModal : undefined} style={styleBg}></div>
        <div className={`${classes.inner} ${props.fullSize ? classes.fullSize : ''} ${classNameInner} `}>
          <div className={classes.closeContainer}>
            {!noClose && <div className={classes.close} onClick={handleCloseModal}></div>}
          </div>
          {children}
        </div>
      </div>
    </>
  )
}

export default withStyles(styles)(Modal)
