/* VARIOUS */

export const Langs = ['es', 'ca'];
export const LangDefault = 'es';

let setup;
if (process.env.NODE_ENV === 'production') {
  setup = {
    baseURL: '',
    contributionPrice: 200,
    contributionExtraPrice: 50,
    ReaptchaPublicES: '6LdlfcQUAAAAACulx1i8SzFXpHVzC_M1Z1kDJWmT',
    ReaptchaPublicCA: '6Le4BX4cAAAAAKJEFRJETsGRG-xT1qhQwshuH-BB',
    urlES: 'https://www.xavifernandezviajes.com/',
    urlCA: 'https://www.xavifernandezviatges.com'
  };
} else {
  setup = {
    baseURL: '',
    contributionPrice: 200,
    contributionExtraPrice: 50,
    ReaptchaPublicES: '6LdlfcQUAAAAACulx1i8SzFXpHVzC_M1Z1kDJWmT',
    ReaptchaPublicCA: '6Le4BX4cAAAAAKJEFRJETsGRG-xT1qhQwshuH-BB',
    urlES: '/',
    urlCA: '/'
  };
}

export const Config = setup;

/* UI */
const drawerOpen = window.innerWidth > 800 ? true : false;
export const UIConfig = {
  drawer: drawerOpen
};

/* BLOG */
export const BlogConfig = {
  first: 4, // # posts to show
  type: '', // Types default
  labels: '' // Labels default
};

/* CUSTOM */
export const ViewerConfig = {
  noToolbar: true
};
