export function imgNameToString(name) {
  return String(name)
    .replaceAll('--', ' ')
    .replaceAll('-', ' ')
    .replaceAll('__', ' ')
    .replaceAll('_', ' ')
    .split('.')
    .slice(0, -1)
    .join(' ')
}
