import React, { useEffect, useRef } from 'react';
import { LinksDrawerMobile } from 'helpers/routes';
import './styles.css';

const NavMobile = () => {
  const inputRef: any = useRef<HTMLElement>(null);

  const close = () => {
    inputRef.current.checked = false;
  };

  useEffect(() => {
    return close();
  });

  return (
    <>
      <nav className='nav-mobile-react-comp' role='navigation'>
        <div className='toggle'>
          <input type='checkbox' ref={inputRef} />
          <span></span>
          <span></span>
          <span></span>
          <ul>
            <LinksDrawerMobile />
          </ul>
          <div className='bg' onMouseUp={close} />
        </div>
      </nav>
    </>
  );
};

export default NavMobile;
