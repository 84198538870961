import { Action } from 'redux';
import { BlogConfig } from 'helpers/config';

export interface IBlog {
  first: number; // # posts to show
  type: string; // Filter type
  labels: string; // Filter labels
  page: number; // Current page
  posts: any; // Post Container
}

export class Blog implements IBlog {
  first: number;
  type: string;
  labels: string;
  page: number;
  posts: any;

  constructor() {
    this.first = BlogConfig.first;
    this.type = BlogConfig.type;
    this.labels = BlogConfig.labels;
    this.page = 0;
    this.posts = null;
  }
}

export enum BlogActionType {
  SET_VARS = 'SET_VARS'
}

export interface IBlogAppAction extends Action<BlogActionType> {
  payload?: any;
}
