/* CMS */
export const ADMIN = (email, password) => `
  {
    admin (
      email:"${email}",
      password: "${password}"
    )

    {
      _id,
      email,
      password,
      name,
      roles,
      lang
    }
  }
`;

export const ADMIN_UPDATE = `
  mutation ($newData: AdminTypeInput) {
    updateAdmin (data: $newData) {
      _id,
     email,
     lang
  }
 }
`;

export const POST = vars => {
  if (vars) vars = `(${vars})`;
  return `
    {
      post
        ${vars}

      {
        _id,
        state,
        type,
        txt,
        labels,
        author,
        expert,
        client,
        stars,
        date,
        bg1,
        bg2
      }
    }
  `;
};

export const POSTS_COUNT = vars => {
  if (vars) vars = `(${vars})`;
  return `{postsCount${vars}}`;
};

export const POSTS = vars => {
  if (vars) vars = `(${vars})`;

  return `
    {
      posts
        ${vars}

        {
        _id,
        state,
        type,
        txt,
        labels,
        author,
        expert,
        client,
        stars,
        date,
        bg1,
        bg2,
        comments
      }
    }
  `;
};

export const POST_ADD = `
  mutation ($newData: PostTypeInput) {
    addPost (data: $newData) {
      _id,
      state,
      type,
      labels,
      txt,
      author,
      expert,
      date,
      bg1,
      bg2
    }
  }
`;

export const POST_UPDATE = `
  mutation ($newData: PostTypeInput) {
    updatePost (data: $newData) {
      _id,
      state,
      type,
      labels,
      txt,
      author,
      expert,
      date,
      bg1,
      bg2
    }
  }
`;

export const COMMENTS = vars => {
  if (vars) vars = `(${vars})`;

  return `
    {
      comments
        ${vars}

        {
          _id,
          state,
          post,
          date,
          name,
          email,
          message,
          user,
          responseTo
      }
    }
  `;
};

export const COMMENT_ADD = `
  mutation ($newData: CommentTypeInput) {
    addComment (data: $newData) {
    _id
    }
  }
`;

export const COMMENT_UPDATE = `
  mutation ($newData: CommentTypeInput) {
    updateComment (data: $newData) {
      _id
    }
  }
`;

export const WEDDINGS = `
  {
    weddings {
      _id,
      state,
      name,
      date
    }
  }
`;

export const WEDDING = (_id = '') => `
  {
    wedding (
      _id:"${_id}"
    )

    {
      _id,
      state,
      name,
      description,
      image,
      place,
      date,
      price,
      product {
        _id,
        type,
        state,
        name,
        destiny,
        price,
        image,
        description
      },
      extras {
        _id,
        type,
        state,
        name,
        product,
        price,
        image,
        description
      }
    }
  }
`;

export const WEDDING_PARTIAL_ID = (_id = '', state = '') => `
  {
    weddingPartialIdState (
      _id: "${_id}",
      state: "${state}"
    )
    {
      _id,
    }
  }
`;

export const WEDDING_ADD = `
  mutation ($newData: WeddingTypeInput) {
    addWedding (data: $newData) {
      _id,
      state,
      name,
      description,
      image,
      place,
      date,
      price
      product {
        _id
      },
      extras {
        _id
      }
    }
  }
`;

export const WEDDING_UPDATE = `
  mutation ($newData: WeddingTypeInput) {
    updateWedding (data: $newData) {
      _id,
      state,
      name,
      description,
      image,
      place,
      date,
      price,
      product {
        _id
      },
      extras {
        _id
      }
    }
  }
`;

export const WEDDING_PRODUCTS = `
  {
    weddingProducts {
      _id,
      type,
      state,
      name
    }
  }
`;

export const WEDDING_PRODUCTS_ACTIVE = `
  {
    weddingProducts (
      state:"active",
    )

    {
      _id,
      type,
      state,
      name,
      destiny,
      price,
      image,
      description
    }
  }
`;

export const WEDDING_PRODUCTS_EXTRAS = `
  {
    weddingProductsExtras {
      _id,
      type,
      state,
      name
    }
  }
`;

export const WEDDING_PRODUCTS_EXTRAS_ACTIVE = (product = '') => `
  {
    weddingProductsExtras (
      product:"${product}",
      state:"active"
    )

    {
      _id,
      type,
      state,
      name,
      product,
      price,
      image,
      description
    }
  }
`;

export const WEDDING_PRODUCT_BY_ID = (_id = '') => `
  {
    weddingProduct (
      _id:"${_id}"
    )

    {
      _id,
      type,
      state,
      name,
      destiny,
      price,
      image,
      description
    }
  }
`;

export const WEDDING_PRODUCT_ADD = `
  mutation ($newData: WeddingProductTypeInput) {
    addWeddingProduct (data: $newData) {
      _id,
      type,
      state,
      name,
      destiny,
      price,
      image,
      description
    }
  }
`;

export const WEDDING_PRODUCT_UPDATE = `
  mutation ($newData: WeddingProductTypeInput) {
    updateWeddingProduct (data: $newData) {
      _id,
      type,
      state,
      name,
      destiny,
      price,
      image,
      description
    }
  }
`;

export const WEDDING_PRODUCT_EXTRA_BY_ID = (_id = '') => `
  {
    weddingProductExtra (
      _id:"${_id}"
    )

    {
      _id,
      type,
      state,
      name,
      product,
      price,
      image,
      description
    }
  }
`;

export const WEDDING_PRODUCT_EXTRA_ADD = `
  mutation ($newData: WeddingProductExtraTypeInput) {
    addWeddingProductExtra (data: $newData) {
      _id,
      type,
      state,
      name,
      product,
      price,
      image,
      description
    }
  }
`;

export const WEDDING_PRODUCT_EXTRA_UPDATE = `
  mutation ($newData: WeddingProductExtraTypeInput) {
    updateWeddingProductExtra (data: $newData) {
      _id,
      type,
      state,
      name,
      product,
      price,
      image,
      description
    }
  }
`;

export const WEDDING_CONTRIBUTIONS = vars => {
  if (vars) vars = `(${vars})`;

  return `
    {
      weddingContributions
        ${vars}

        {
        _id,
        code,
        weddingId,        
        weddingName,        
        date,
        name,
        email,
        amount,
        product,
        extras,
        state,
        name,
        message
      }
    }
  `;
};

export const WEDDING_CONTRIBUTIONS_BY_ID = _id => {
  return `
  {
    weddingContributions (
      wedding: "${_id}",
      state: "1"
    )

    {
      _id,
      date,
      name,
      message,
      email,
      amount,
      product,
      extras
    }
  }
`;
};

export const WEDDING_CONTRIBUTION = vars => {
  if (vars) vars = `(${vars})`;

  return `
    {
      weddingContribution
        ${vars}

        {
        _id,
        code,
        weddingId,        
        weddingName,        
        date,
        name,
        email,
        amount,
        product,
        extras,
        state,
        name,
        message
      }
    }
  `;
};

export const WEDDING_CONTRIBUTIONS_ADD = `
  mutation ($newData: WeddingContributionTypeInput) {
    addWeddingContribution (data: $newData) {
     _id,
    }
  }
`;

export const WEDDING_CONTRIBUTIONS_UPDATE = `
  mutation ($newData: WeddingContributionTypeUpdateInput) {
    updateWeddingContribution (data: $newData) {
    _id,
    }
  }
`;

export const WEDDING_CONTRIBUTIONS_TPV_UPDATE = `
  mutation ($newData: WeddingContributionTpvTypeUpdateInput) {
    updateWeddingContributionTpv (data: $newData) {
    _id,
    }
  }
`;

export const DESTINATIONS = `
  {
    destinations {
      _id,
      txt,
      idestination,
      expert
    }
  }
`;

export const DESTINATION = vars => {
  if (vars) vars = `(${vars})`;
  return `
    {
      destination
        ${vars}

      {
        _id,
        state,
        txt,
        ifooter,
        idestination,
        imap,
        iteaser,
        expert,
        thumbnails,
        places
      }
    }
  `;
};

export const DESTINATIONS_BY_ID_STATE = `
  query ($ids: [String], $state: String) {
    destinations (ids: $ids, state: $state) {
      _id,
      txt,
      idestination,
      expert
    }
  }
`;

export const DESTINATION_ADD = `
  mutation ($newData: DestinationTypeInput) {
    addDestination (data: $newData) {
      _id
    }
  }
`;

export const DESTINATION_UPDATE = `
  mutation ($newData: DestinationTypeInput) {
    updateDestination (data: $newData) {
      _id
    }
  }
`;

export const EXPERTS = (state = '') => `
  {
    experts (state: "${state}") {
      _id,
      txt,
      name,
      state
    }
  }
`;

export const EXPERT = vars => {
  if (vars) vars = `(${vars})`;
  return `
    {
      expert
        ${vars}

      {
        _id,
        state,
        name,
        txt,
        images
      }
    }
  `;
};

export const EXPERT_ADD = `
  mutation ($newData: ExpertTypeInput) {
    addExpert (data: $newData) {
      _id
    }
  }
`;

export const EXPERT_UPDATE = `
  mutation ($newData: ExpertTypeInput) {
    updateExpert (data: $newData) {
      _id
    }
  }
`;
