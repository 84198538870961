import React from 'react'
import { Router } from 'helpers/routes'
import Header from './Header/header'
import Footer from './Footer/footer'
import Cookies from './Cookies/cookies'
import Toast from 'components/Toast'
import { useTags } from '@utils/Utils'

const Layout = () => {
  useTags()

  return (
    <>
      <Header />
      <Router />
      <Footer />
      <Cookies />
      <Toast />
    </>
  )
}

export default Layout
