import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

/* REDUCERS */
import { UIReducers } from 'layout/UI/uiReducers'
import { BlogReducers } from 'layout/Blog/blogReducers'

/* COMBINE */
const reducers = combineReducers({
  ui: UIReducers,
  blog: BlogReducers
})

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)))