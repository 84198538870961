import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './custom.css'
import { toast as t } from 'react-toastify'

export const toast = t

const Toast = () => {
  return (
    <ToastContainer
      position='bottom-right'
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
    />
  )
}

export default Toast
